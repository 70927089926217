<template>
  <div id="map" style="height: 100%; width: 100%"></div>
</template>

<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "Map",
  data: () => ({
    uluru: { lat: 21.6523857, lng: -98.3736045 },
    map: null,
    markers: [],
  }),

  computed: {
    ...mapState(["color"]),
    UserMap: {
      get: function () {
        return this.$store.state.UserMap;
      },
    },
  },
  watch: {
    UserMap: function (users) {
      for (var i = 0; i < users.length; i++) {
        this.markers.push({
          id: users[i].IdUsuario,
          marker: new google.maps.Marker({
            position: {
              lat: users[i].last_location_lat,
              lng: users[i].last_location_lng,
            },
            map: this.map,
            icon:
              users[i].is_on_sos == 1
                ? {
                    url: "../img/warningamber.png", // url
                    scaledSize: new google.maps.Size(32, 32), // scaled size
                    anchor: new google.maps.Point(16, 16), // anchor
                  }
                : null,
          }),
          infoWindow: new google.maps.InfoWindow({
            content: this.generateInfowindow(users[i]),
          }),
        });
        var mrk = this.markers[this.markers.length - 1].marker;
        var inf = this.markers[this.markers.length - 1].infoWindow;
        mrk.addListener(
          "click",
          (function (infow, mark) {
            return function () {
              infow.open(map, mark);
            };
          })(inf, mrk)
        );
      }
    },
  },

  mounted() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 6,
      center: this.uluru,
    });
    this.$store.dispatch("AllUsers");
  },

  methods: {
    generateInfowindow(user) {
      if (user.last_location_bearing == 0) {
        var Direction = "No Disponible (estacionario)";
      } else if (
        user.last_location_bearing > 340 ||
        user.last_location_bearing <= 20
      ) {
        var Direction = user.last_location_bearing + "(N)";
      } else if (
        user.last_location_bearing > 20 &&
        user.last_location_bearing <= 70
      ) {
        var Direction = user.last_location_bearing + "(NE)";
      } else if (
        user.last_location_bearing > 70 &&
        user.last_location_bearing <= 110
      ) {
        var Direction = user.last_location_bearing + "(E)";
      } else if (
        user.last_location_bearing > 110 &&
        user.last_location_bearing <= 160
      ) {
        var Direction = user.last_location_bearing + "(SE)";
      } else if (
        user.last_location_bearing > 160 &&
        user.last_location_bearing <= 200
      ) {
        var Direction = user.last_location_bearing + "(S)";
      } else if (
        user.last_location_bearing > 200 &&
        user.last_location_bearing <= 250
      ) {
        var Direction = user.last_location_bearing + "(SO)";
      } else if (
        user.last_location_bearing > 250 &&
        user.last_location_bearing <= 290
      ) {
        var Direction = user.last_location_bearing + "(O)";
      } else if (
        user.last_location_bearing > 290 &&
        user.last_location_bearing <= 340
      ) {
        var Direction = user.last_location_bearing + "(NO)";
      }

      return (
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h1 id="firstHeading" class="firstHeading">' +
        user.name +
        "</h1>" +
        '<div id="bodyContent">' +
        "<p>" +
        "<p><b>Ultima Ubicacion:</b> Lat:" +
        user.last_location_lat +
        " Lng:" +
        user.last_location_lng +
        "</p>" +
        "<p><b>Velocidad:</b> " +
        user.last_location_speed * 3.6 +
        "km/h</p>" +
        "<p><b>Direccion:</b> " +
        Direction +
        "</p>" +
        "<p><b>Ultima actualizacion:</b> " +
        moment(user.last_location_update).format("DD/MM/YY hh:mm:ss") +
        "</p>" +
        '<p><a class="btn btn-primary btn-block" href="/tracking/' +
        user.IdUsuario +
        '" >Ver historial</a></p>' +
        "</div>" +
        "</div>"
      );
    },
  },
};
</script>
