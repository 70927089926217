<template>
  <div>
    <v-app-bar
      :color="color"
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      tile
      dense
    >
      <v-app-bar-title>Jomar App</v-app-bar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab
            v-for="item in menu"
            :key="item"
            v-on:click="GoRoute(item.path)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>
      <v-btn v-on:click="Exit()" icon>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navbar",
  data: () => ({
    menu: [],
  }),
  computed: {
    ...mapState(["menuA", "menuS", "color", "userInfo"]),
    tab: {
      get: function () {
        return this.$store.state.tab;
      },
      set: function (val) {
        this.$store.commit("setTab", val);
      },
    },
  },
  methods: {
    Exit() {
      this.$store.commit("ExitApp");
    },
    GoRoute(ruta) {
      this.$router.push(ruta);
    },
  },
  mounted() {
    if (this.userInfo.role == "admin") {
      this.menu = this.menuA;
    } else {
      this.menu = this.menuS;
    }
  },
};
</script>

<style>
.v-app-bar-title__content {
  width: 100px !important;
}
</style>
