<template>
  <div>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col xl="3" lg="3" md="6" cols="12">
            <v-autocomplete
              v-model="SucSelected"
              :items="sucursales"
              item-text="sucursal"
              item-value="sucursal"
              :rules="[(v) => !!v || 'Seleccione sucursal']"
              dense
              solo
              label="Sucursales"
              required
            ></v-autocomplete>
          </v-col>
          <v-col xl="3" lg="3" md="6" cols="12">
            <v-btn dense dark :color="color" block v-on:click="BuscaClientes()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="HeadersLClients"
              :items="listClients"
              :search="search"
              :loading="loadingLClient"
              loading-text="Cargando... Por favor espere"
            >
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn small color="success" v-on:click="GoRoute(item.Clave)">
                  Editar
                  <v-icon small> mdi-circle-edit-outline </v-icon>
                </v-btn>
                <v-btn small color="success" v-on:click="QR(item)"> QR </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-dialog v-model="QRdialog" width="300">
          <v-card>
            <v-card-title>
              <h1>Cliente QR</h1>
              <v-spacer></v-spacer>
              <v-btn icon v-on:click="QRdialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <qrcode-vue :value="value" :size="250" level="H" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text style="padding-top: 10px">
              <h2>{{ value2 }}</h2>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import QrcodeVue from "qrcode.vue";
export default {
  name: "listClients",
  components: {
    QrcodeVue,
  },
  data: () => ({
    SucSelected: null,
    search: "",
    valid: true,
    QRdialog: false,
    value: "",
    value2: "",
  }),
  computed: {
    ...mapState([
      "sucursales",
      "color",
      "loadingLClient",
      "HeadersLClients",
      "listClients",
    ]),
  },
  mounted() {
    this.$store.dispatch("GetSucursales");
    this.$store.commit("SetListClients", []);
  },
  methods: {
    BuscaClientes() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("GetListClients", this.SucSelected);
      }
    },
    GoRoute(Clave) {
      this.$store.commit("setTab", 3);
      this.$router.push(`createClient/${Clave}`);
    },
    QR(item) {
      this.QRdialog = true;
      this.value = item.Clave;
      this.value2 = `${item.Clave} - ${item.RazonSocial}`;
    },
  },
};
</script>
<style></style>
