<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="HeadersLAdmins"
              :items="listAdmins"
              :search="search"
              :loading="loadingLAdmin"
              loading-text="Cargando... Por favor espere"
            >
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  small
                  color="success"
                  v-on:click="GoRoute(item.IdUsuario)"
                >
                  Editar
                  <v-icon small> mdi-circle-edit-outline </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.active == 1"
                  small
                  color="error"
                  v-on:click="DeleteUserDialogue(item.IdUsuario)"
                >
                  Desactivar
                  <v-icon small> mdi-account-off </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  color="success"
                  v-on:click="ActivateUserDialog(item.IdUsuario)"
                >
                  Activar
                  <v-icon small> mdi-account </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDes" max-width="400" style="border-radius: 12px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <sweetalert-icon v-if="visibleAction == 0" icon="warning" />
                <sweetalert-icon
                  v-else-if="visibleAction == 1"
                  icon="loading"
                />
                <sweetalert-icon v-else icon="success" />
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h2>{{ textDiagAdmin.Title }}</h2>
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h3>{{ textDiagAdmin.Body }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 12px 16px">
            <v-spacer></v-spacer>
            <v-btn
              v-if="visibleAction == 2"
              color="success"
              @click="dialogDes = false"
            >
              Aceptar
            </v-btn>
            <v-btn
              v-if="visibleAction == 0"
              color="grey lighten-4"
              @click="dialogDes = false"
            >
              No
            </v-btn>
            <v-btn
              v-if="visibleAction == 0"
              color="red"
              dark
              v-on:click="DeleteUser()"
            >
              Si
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAct" max-width="400" style="border-radius: 12px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <sweetalert-icon v-if="visibleAction == 0" icon="warning" />
                <sweetalert-icon
                  v-else-if="visibleAction == 1"
                  icon="loading"
                />
                <sweetalert-icon v-else icon="success" />
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h2>{{ textDiagAdmin.Title }}</h2>
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h3>{{ textDiagAdmin.Body }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 12px 16px">
            <v-spacer></v-spacer>
            <v-btn
              v-if="visibleAction == 2"
              color="success"
              @click="dialogAct = false"
            >
              Aceptar
            </v-btn>
            <v-btn
              v-if="visibleAction == 0"
              color="grey lighten-4"
              @click="dialogAct = false"
            >
              No
            </v-btn>
            <v-btn
              v-if="visibleAction == 0"
              color="red"
              dark
              v-on:click="ActivateUser()"
            >
              Si
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "listAdmin",
  data: () => ({
    search: "",
    IdUser: "",
    dialogDes: false,
    dialogAct: false,
    idToToggle: "",
  }),
  computed: {
    ...mapState(["HeadersLAdmins", "listAdmins", "loadingLAdmin"]),
    visibleAction: {
      get: function () {
        return this.$store.state.visibleAction;
      },
      set: function (val) {
        return (this.$store.state.visibleAction = val);
      },
    },
    textDiagAdmin: {
      get: function () {
        return this.$store.state.textDiagAdmin;
      },
      set: function (val) {
        return (this.$store.state.textDiagAdmin = val);
      },
    },
  },
  mounted() {
    this.$store.dispatch("GetUsers", "admin");
  },
  methods: {
    DeleteUserDialogue(id) {
      this.textDiagAdmin = {
        Title: "Desactivar Usuario",
        Body: "¿Desea desactivar este usuario?",
      };
      this.idToToggle = id;
      this.visibleAction = 0;
      this.dialogDes = !this.dialogDes;
    },
    DeleteUser() {
      this.visibleAction = 1;
      this.textDiagAdmin = {
        Title: "Desactivando Usuario",
        Body: "Por favor, espere...",
      };
      this.$store.dispatch("DesactivarUser", this.idToToggle);
    },
    ActivateUserDialog(id) {
      this.textDiagAdmin = {
        Title: "Activar Usuario",
        Body: "¿Desea activar este usuario?",
      };
      this.idToToggle = id;
      this.visibleAction = 0;
      this.dialogAct = !this.dialogAct;
    },
    ActivateUser(id) {
      this.visibleAction = 1;
      this.$store.dispatch("ActivarUser", this.idToToggle);
    },
    GoRoute(IdUsuario) {
      this.$store.commit("setTab", 4);
      this.$router.push(`createAdmin/${IdUsuario}`);
    },
  },
};
</script>
<style></style>
