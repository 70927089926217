<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="SucSelected"
          :items="sucursales"
          item-text="sucursal"
          item-value="sucursal"
          dense
          solo
          label="Sucursales"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-btn dense dark :color="color" @click="BuscarClientes()"
          >Buscar</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(day) in days" :key="day" cols="3">
        <v-card
          ><v-container
            ><v-row
              ><v-col
                ><h3>{{ day }}</h3></v-col
              >
              <v-col
                ><v-btn dark :color="color" @click="BorrarDiaEntero(day)"
                  >Borrar Todo</v-btn
                ></v-col
              ></v-row
            ><v-row
              ><v-col>
                <v-autocomplete
                  v-model="clientSelected[day]"
                  :items="listClients"
                  :item-text="getAutocompleteName"
                  item-value="Clave"
                  dense
                  solo
                  label="Cliente"
                ></v-autocomplete
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block dark color="success" @click="AgregarARuta(day)"
                  >Agregar</v-btn
                ></v-col
              >
            </v-row></v-container
          >
          <v-list-item v-for="ruta in rutas[day]" :key="ruta.Clave" dense>
            <v-list-item-title class="text-wrap">{{
              ruta.RazonSocial
            }}</v-list-item-title>

            <v-list-item-icon>
              <v-icon small @click="MoveUp(day, ruta.Clave)"
                >mdi-chevron-up</v-icon
              >
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon small @click="MoveDown(day, ruta.Clave)"
                >mdi-chevron-down</v-icon
              >
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon small @click="BorrarDia(day, ruta.Clave)"
                >mdi-close</v-icon
              >
            </v-list-item-icon>
          </v-list-item></v-card
        >
      </v-col>
    </v-row>
    <v-row>
      <v-btn block dark :color="color" @click="EditUserRuta()"
        >Guardar</v-btn
      ></v-row
    >
    <v-dialog v-model="dialogDes" max-width="400" style="border-radius: 12px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <sweetalert-icon icon="warning" />
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h2>Salir sin guardar</h2>
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h3>¿Desea irse sin guardar sus cambios?</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 12px 16px">
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-4" @click="dialogDes = false"> No </v-btn>
          <v-btn color="red" dark v-on:click="salir()"> Si </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-container
  >
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AssignRoute",
  beforeRouteLeave(to, from, next) {
    this.destinationURL = to;
    if (JSON.stringify(this.rutas) === JSON.stringify(this.rutasIniciales)) {
      console.log("son iguales");

      next();
    } else {
      this.dialogDes = !this.dialogDes;
      if (this.leaveComponent) {
        next();
      }
    }
  },

  data: () => ({
    SucSelected: "",
    clientSelected: {
      Lunes: "",
      Martes: "",
      Miercoles: "",
      Jueves: "",
      Viernes: "",
      Sabado: "",
      Domingo: "",
    },
    days: [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ],
    rutas: {
      Lunes: [],
      Martes: [],
      Miercoles: [],
      Jueves: [],
      Viernes: [],
      Sabado: [],
      Domingo: [],
    },
    rutasIniciales: {
      Lunes: [],
      Martes: [],
      Miercoles: [],
      Jueves: [],
      Viernes: [],
      Sabado: [],
      Domingo: [],
    },
    dialogDes: false,
    destinationURL: "",
    leaveComponent: false,
  }),

  computed: {
    ...mapState(["sucursales", "color", "rutaUser"]),
    listClients: {
      get: function () {
        return this.$store.state.listClients;
      },
    },
  },

  async mounted() {
    this.$store.dispatch("GetSucursales");
    this.$store.commit("SetListClients", []);
    await this.$store.dispatch("GetRutaUser", this.$route.params);
    for (let ruta of this.rutaUser) {
      this.rutas[ruta.Dia].push(ruta);
      this.rutasIniciales[ruta.Dia].push(ruta);
    }
  },

  methods: {
    async BuscarClientes() {
      if (this.SucSelected != "") {
        await this.$store.dispatch("GetListClients", this.SucSelected);
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Datos de clientes seleccionados cargados a las listas",
        });
      }
    },

    getAutocompleteName(item) {
      return `${item.Clave} ${item.RazonSocial}`;
    },

    BorrarDiaEntero(day) {
      this.rutas[day] = [];
    },

    BorrarDia(day, clave) {
      for (let i = 0; i < this.rutas[day].length; i++) {
        if (this.rutas[day][i].Clave == clave) {
          this.rutas[day].splice(i, 1);
          break;
        }
      }
    },

    MoveUp(day, clave) {
      for (let i = 0; i < this.rutas[day].length; i++) {
        if (this.rutas[day][i].Clave == clave) {
          if (i === 0) {
            return;
          }
          let temporal = this.rutas[day][i];
          this.rutas[day].splice(i - 1, 0, temporal);
          this.rutas[day].splice(i + 1, 1);
        }
      }
    },

    MoveDown(day, clave) {
      for (let i = 0; i < this.rutas[day].length; i++) {
        if (this.rutas[day][i].Clave == clave) {
          if (i === this.rutas[day].length - 1) {
            return;
          }
          let temporal = this.rutas[day][i];
          this.rutas[day].splice(i, 1);
          this.rutas[day].splice(i + 1, 0, temporal);
          break;
        }
      }
    },

    AgregarARuta(day) {
      for (let i = 0; i < this.listClients.length; i++) {
        if (this.listClients[i].Clave == this.clientSelected[day]) {
          this.rutas[day].push(this.listClients[i]);
          break;
        }
      }
    },

    EditUserRuta() {
      let allRoutes = {
        Lunes: "",
        Martes: "",
        Miercoles: "",
        Jueves: "",
        Viernes: "",
        Sabado: "",
        Domingo: "",
      };

      for (let day in allRoutes) {
        for (let ruta of this.rutas[day]) {
          allRoutes[day] += ruta.Clave + ",";
        }
      }
      console.log(allRoutes);
      this.$store.dispatch("EditUserRuta", {
        id: this.$route.params.id,
        rutas: allRoutes,
      });
    },

    salir() {
      this.leaveComponent = true;
      this.$router.push(this.destinationURL)
    },
  },
};
</script>
