var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"align-center":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('validation-provider',{attrs:{"name":"Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"label":"Usuario","required":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"label":"Password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Telefono","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"type":"number","label":"Telefono","counter":10,"required":""},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Telefono Secundario","rules":"length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"type":"number","label":"Telefono Secundario","counter":10},model:{value:(_vm.SNumber),callback:function ($$v) {_vm.SNumber=$$v},expression:"SNumber"}})]}}],null,true)}),(_vm.visible)?_c('v-btn',{attrs:{"disabled":invalid,"dark":!invalid,"color":_vm.color,"block":"","type":"submit"},on:{"click":function($event){return _vm.CreaAdmin()}}},[_vm._v(" Crea Admin ")]):_c('v-btn',{attrs:{"disabled":invalid,"dark":!invalid,"color":_vm.color,"block":""},on:{"click":function($event){return _vm.EditaAdmin()}}},[_vm._v(" Edita Admin ")])],1)]}}])}),_c('v-dialog',{staticStyle:{"border-radius":"12px"},attrs:{"max-width":"400"},model:{value:(_vm.dialogSave),callback:function ($$v) {_vm.dialogSave=$$v},expression:"dialogSave"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.vActCAdmin == 1)?_c('sweetalert-icon',{attrs:{"icon":"loading"}}):_c('sweetalert-icon',{attrs:{"icon":"success"}})],1)],1),_c('v-row',{staticStyle:{"text-align":"center"}},[_c('v-col',{staticStyle:{"padding":"5px"},attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.textDiagCAdmin.Title))])])],1),_c('v-row',{staticStyle:{"text-align":"center"}},[_c('v-col',{staticStyle:{"padding":"5px"},attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.textDiagCAdmin.Body))])])],1)],1),_c('v-card-actions',{staticStyle:{"padding":"12px 16px"}},[_c('v-spacer'),(_vm.vActCAdmin == 2)?_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.Guardar()}}},[_vm._v(" Aceptar ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }