<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="HeadersLSupervisor"
              :items="listSupervisor"
              :search="search"
            >
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  small
                  color="success"
                  v-on:click="GoRoute(item.IdUsuario)"
                >
                  Editar
                  <v-icon small> mdi-circle-edit-outline </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.active == 1"
                  small
                  color="error"
                  v-on:click="Desactivar(item.IdUsuario)"
                >
                  Desactivar
                  <v-icon small> mdi-account-off </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  color="success"
                  v-on:click="Activar(item.IdUsuario)"
                >
                  Activar
                  <v-icon small> mdi-account </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDes" max-width="400" style="border-radius: 12px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <sweetalert-icon v-if="visibleAction == 0" icon="warning" />
                <sweetalert-icon
                  v-else-if="visibleAction == 1"
                  icon="loading"
                />
                <sweetalert-icon v-else icon="success" />
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h2>{{ textDiagSupervisor.Title }}</h2>
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h3>{{ textDiagSupervisor.Body }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 12px 16px">
            <v-spacer></v-spacer>
            <v-btn
              v-if="visibleAction == 2"
              color="success"
              @click="dialogDes = false"
            >
              Aceptar
            </v-btn>
            <v-btn color="grey lighten-4" @click="dialogDes = false" v-if="visibleAction != 2" >
              No
            </v-btn>
            <v-btn color="red" dark v-if="visibleAction != 2" v-on:click="Desactiva()"> Si </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAct" max-width="400" style="border-radius: 12px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <sweetalert-icon v-if="visibleAction == 0" icon="warning" />
                <sweetalert-icon
                  v-else-if="visibleAction == 1"
                  icon="loading"
                />
                <sweetalert-icon v-else icon="success" />
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h2>{{ textDiagSupervisor.Title }}</h2>
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h3>{{ textDiagSupervisor.Body }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 12px 16px">
            <v-spacer></v-spacer>
            <v-btn
              v-if="visibleAction == 2"
              color="success"
              @click="dialogAct = false"
            >
              Aceptar
            </v-btn>
            <v-btn color="grey lighten-4" @click="dialogAct = false" v-if="visibleAction != 2">
              No
            </v-btn>
            <v-btn color="red" v-if="visibleAction != 2" dark v-on:click="Activa()"> Si </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "listSupervisor",
  data: () => ({
    search: "",
    IdUser: "",
    dialogDes: false,
    dialogAct: false,
  }),
  computed: {
    ...mapState([
      "HeadersLSupervisor",
      "listSupervisor",
    ]),
    visibleAction: {
      get: function () {
        return this.$store.state.visibleAction;
      },
      set: function (val) {
        return (this.$store.state.visibleAction = val);
      },
    },
    textDiagSupervisor: {
      get: function () {
        return this.$store.state.textDiagSupervisor;
      },
      set: function (val) {
        return (this.$store.state.textDiagSupervisor = val);
      },
    }
  },
  mounted() {
    this.$store.dispatch("GetUsers", "supervisor");
  },
  methods: {
    Activar(IdUsuario) {
      this.IdUser = IdUsuario;
      const obj = {
        Title: "Activar Usuario",
        Body: "¿Desea activar este usuario?",
      };
      this.visibleAction= 0
      this.dialogAct= 1
      this.textDiagSupervisor= obj
    },
    Activa() {
      const obj = {
        Title: "Activando",
        Body: "Por favor, espere...",
      };
      this.visibleAction= 1
      this.textDiagSupervisor= obj
      this.$store.dispatch("ActivarSupervisor", this.IdUser);
    },
    Desactivar(IdUsuario) {
      this.IdUser = IdUsuario;
      const obj = {
        Title: "Desactivar Usuario",
        Body: "¿Desea desactivar este usuario?",
      };
      this.visibleAction= 0
      this.dialogDes= !this.dialogDes
      this.textDiagSupervisor= obj
    },
    Desactiva() {
      const obj = {
        Title: "Desactivando",
        Body: "Por favor, espere...",
      };
      this.visibleAction= 1
      this.textDiagSupervisor= obj
      this.$store.dispatch("DesactivarSupervisor", this.IdUser);
    },
    GoRoute(IdUsuario) {
      this.$store.commit("setTab", 6);
      this.$router.push(`createSupervisor/${IdUsuario}`);
    },
  },
};
</script>
<style></style>

</script>
<style></style>
