<template>
  <v-container>
    <v-row>
      <v-col>
        <v-menu
          ref="startMenu"
          v-model="startMenu"
          :close-on-content-click="false"
          :return-value.sync="dates[0]"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dates[0]"
              label="Inicio"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates[0]" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.startMenu.save(dates[0])">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          :return-value.sync="dates[1]"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dates[1]"
              label="Final"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates[1]" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="endMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.endMenu.save(dates[1])">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn block @click="searchFindByUser()">Buscar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9" style="height: 80vh">
        <div id="map" style="height: 100%; width: 100%"></div>
      </v-col>
      <v-col>
        <h3>Nombre:</h3>
        <p>{{ gottenUserInfo.name }}</p>
        <h3>Usuario:</h3>
        <p>{{ gottenUserInfo.email }}</p>
        <h3>Telefono:</h3>
        <p>{{ gottenUserInfo.phone }}</p>
        <h3>Ultima ubicacion:</h3>
        <p>
          Lat: {{ gottenUserInfo.last_location_lat }}, Lng:
          {{ gottenUserInfo.last_location_lng }}
        </p>
        <h3>Ultima actualizacion:</h3>
        <p>{{ changeTimestamp(gottenUserInfo.last_location_update) }}</p>
        <h3>Velocidad:</h3>
        <p>{{ gottenUserInfo.last_location_speed }}</p>
        <h3>Direccion:</h3>
        <p>{{ gottenUserInfo.last_location_bearing }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Ultimas alertas de sos</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="SOSHeaders"
          :items="userSOSList"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:item.index="{ item, index }">
            {{ userSOSList.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.location="{ item }">
            Latitud: {{ item.loc_lat }}, Longitud: {{ item.loc_lng }}
          </template>
          <template v-slot:item.datetime="{ item }">
            {{ changeTimestamp(item.timestamp) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Ultimos Check-ins</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="checkInHeaders"
          :items="userCheckins"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:item.index="{ item, index }">
            {{ userCheckins.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.location="{ item }">
            Latitud: {{ item.Latitud }}, Longitud: {{ item.Longitud }}
          </template>
          <template v-slot:item.datetime="{ item }">
            {{ changeTimestamp(item.date) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  name: "Tracking",
  data: () => ({
    uluru: { lat: 21.6523857, lng: -98.3736045 },
    map: null,
    markers: [],
    dates: [
      moment()
        .day(moment().day() - 1)
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ],
    SOSHeaders: [
      { text: "#", value: "index" },
      { text: "Ubicación", value: "location" },
      { text: "Velocidad", value: "speed" },
      { text: "Dirección", value: "bearing" },
      { text: "Fecha y hora", value: "datetime" },
    ],
    checkInHeaders: [
      { text: "#", value: "index" },
      { text: "Ubicación", value: "location" },
      { text: "Cliente", value: "RazonSocial" },
      { text: "Tipo Visita", value: "typeOfVisit" },
      { text: "Fecha y hora", value: "datetime" },
    ],
    startMenu: false,
    endMenu: false,
    poly: new google.maps.Polyline({
      strokeOpacity: 1.0,
      strokeWeight: 3,
      strokeColor: "#FF0000",
    }),
  }),

  methods: {
    changeTimestamp: (timestamp) => {
      return moment(timestamp).format("YY/MM/DD HH:MM:SS");
    },
    setPolyLine(data) {
      this.clearMap();
      this.poly.setMap(this.map);
      var path = this.poly.getPath();
      for (let i = 0; i < data.length; i++) {
        var latlng = new google.maps.LatLng(data[i].loc_lat, data[i].loc_lng);

        let mrk = new google.maps.Marker({
          position: latlng,
          map: this.map,
          icon: {
            url: this.getDirectionIcon(data[i].bearing), // url
            scaledSize: new google.maps.Size(32, 32), // scaled size
            // origin: new google.maps.Point(0,0), // origin
            anchor: new google.maps.Point(16, 16), // anchor
          },
          // label: i.toString(),
        });

        let inf = new google.maps.InfoWindow({
          content: this.generateInfowindow(data[i]),
        });

        path.push(latlng);
        this.markers.push({
          marker: mrk,
          infoWindow: inf,
        });

        this.markers[i].marker.addListener(
          "click",
          (function (marker, infoWindow) {
            return function () {
              console.log(marker);
              infoWindow.open(this.map, marker);
            };
          })(this.markers[i].marker, this.markers[i].infoWindow)
        );
      }
      this.map.setCenter(
        new google.maps.LatLng(data[0].loc_lat, data[0].loc_lng)
      );
      this.map.setZoom(13);
    },
    getDirectionIcon: (bearing) => {
      if (bearing == 0 || bearing == 0) {
        //SIN DIRECCION
        return "https://earth.google.com/images/kml-icons/track-directional/track-none.png";
      } else if (bearing > 348.75 || bearing <= 11.25) {
        // N
        return "https://earth.google.com/images/kml-icons/track-directional/track-0.png";
      } else if (bearing > 11.25 && bearing <= 33.75) {
        // NNE
        return "https://earth.google.com/images/kml-icons/track-directional/track-1.png";
      } else if (bearing > 33.75 && bearing <= 56.25) {
        // NE
        return "https://earth.google.com/images/kml-icons/track-directional/track-2.png";
      } else if (bearing > 56.25 && bearing <= 78.75) {
        // NEE
        return "https://earth.google.com/images/kml-icons/track-directional/track-3.png";
      } else if (bearing > 78.75 && bearing <= 101.25) {
        // E
        return "https://earth.google.com/images/kml-icons/track-directional/track-4.png";
      } else if (bearing > 101.25 && bearing <= 123.75) {
        // SEE
        return "https://earth.google.com/images/kml-icons/track-directional/track-5.png";
      } else if (bearing > 123.75 && bearing <= 146.25) {
        // SE
        return "https://earth.google.com/images/kml-icons/track-directional/track-6.png";
      } else if (bearing > 146.25 && bearing <= 168.75) {
        // SSE
        return "https://earth.google.com/images/kml-icons/track-directional/track-7.png";
      } else if (bearing > 168.75 && bearing <= 191.25) {
        // S
        return "https://earth.google.com/images/kml-icons/track-directional/track-8.png";
      } else if (bearing > 191.25 && bearing <= 213.75) {
        // SSO
        return "https://earth.google.com/images/kml-icons/track-directional/track-9.png";
      } else if (bearing > 213.75 && bearing <= 236.25) {
        // SO
        return "https://earth.google.com/images/kml-icons/track-directional/track-10.png";
      } else if (bearing > 236.25 && bearing <= 258.75) {
        // SOO
        return "https://earth.google.com/images/kml-icons/track-directional/track-11.png";
      } else if (bearing > 258.75 && bearing <= 281.25) {
        // O
        return "https://earth.google.com/images/kml-icons/track-directional/track-12.png";
      } else if (bearing > 281.25 && bearing <= 303.75) {
        // NOO
        return "https://earth.google.com/images/kml-icons/track-directional/track-13.png";
      } else if (bearing > 303.75 && bearing <= 326.25) {
        // NO
        return "https://earth.google.com/images/kml-icons/track-directional/track-14.png";
      } else if (bearing > 326.25 && bearing <= 348.75) {
        // NOO
        return "https://earth.google.com/images/kml-icons/track-directional/track-15.png";
      } else {
        console.log(bearing);
      }
    },

    generateInfowindow(data) {
      var Direction = this.getBearing(data.bearing);
      return (
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h1 id="firstHeading" class="firstHeading">' +
        data.name +
        "</h1>" +
        '<div id="bodyContent">' +
        "<p><b>Ubicacion:</b> Lat:" +
        data.loc_lat +
        " Lng:" +
        data.loc_lng +
        "</p>" +
        "<p><b>Velocidad:</b> " +
        data.speed * 3.6 +
        "km/h</p>" +
        "<p><b>Direccion:</b> " +
        Direction +
        "</p>" +
        "<p><b>Hora y fecha:</b> " +
        moment(data.timestamp).format("DD/MM/YY hh:mm:ss") +
        "</p>" +
        "</div>" +
        "</div>"
      );
    },

    getBearing: (bearing) => {
      if (bearing == 0) {
        var Direction = "No Disponible (estacionario)";
      } else if (bearing > 340 || bearing <= 20) {
        var Direction = bearing + "(N)";
      } else if (bearing > 20 && bearing <= 70) {
        var Direction = bearing + "(NE)";
      } else if (bearing > 70 && bearing <= 110) {
        var Direction = bearing + "(E)";
      } else if (bearing > 110 && bearing <= 160) {
        var Direction = bearing + "(SE)";
      } else if (bearing > 160 && bearing <= 200) {
        var Direction = bearing + "(S)";
      } else if (bearing > 200 && bearing <= 250) {
        var Direction = bearing + "(SO)";
      } else if (bearing > 250 && bearing <= 290) {
        var Direction = bearing + "(O)";
      } else if (bearing > 290 && bearing <= 340) {
        var Direction = bearing + "(NO)";
      }
      return Direction;
    },

    async searchFindByUser() {
      await this.$store.dispatch("findByUser", {
        id: this.$route.params.id,
        dates: this.dates,
      });

      this.setPolyLine(this.userFindByUser);
    },

    clearMap() {
      this.poly.setPath([]);
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].marker.setMap(null);
      }
      this.markers = [];
    },
  },

  async mounted() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 6,
      center: this.uluru,
    });
    this.$store.dispatch("getUserInfo", this.$route.params);
    this.$store.dispatch("findByUser", {
      id: this.$route.params.id,
      dates: this.dates,
    });
    this.$store.dispatch("findUserSOSList", this.$route.params);
    await this.$store.dispatch("findUserCheckins", this.$route.params);
    this.setPolyLine(this.userFindByUser);
  },

  computed: {
    ...mapState(["color"]),
    gottenUserInfo: {
      get: function () {
        return this.$store.state.gottenUserInfo;
      },
    },
    userSOSList: {
      get: function () {
        return this.$store.state.userSOSList;
      },
    },
    userCheckins: {
      get: function () {
        return this.$store.state.userCheckins;
      },
    },
    userFindByUser: {
      get: function () {
        return this.$store.state.userFindByUser;
      },
    },
  },
};
</script>
