<template>
  <div>
    <v-container align-center>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          dense
          outlined
          v-model="name"
          :rules="nameRules"
          label="Nombre"
          required
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="address"
          :rules="addressRules"
          label="Dirección"
          required
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="ClaveCliente"
          label="Clave de cliente en jomarnet"
          required
        ></v-text-field>
        <div
          id="map"
          style="height: 550px; width: 100%; margin-bottom: 20px"
        ></div>
        <v-btn dark :color="color" block v-on:click="EditaCliente()">
          Edita Cliente
        </v-btn>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "createClient",
  data: () => ({
    name: "",
    nameRules: [(v) => !!v || "Nombre es requerido"],
    address: "",
    addressRules: [(v) => !!v || "Dirección es requerido"],
    ClaveCliente: "",
    valid: true,
    map: null,
    uluru: { lat: 21.6523857, lng: -98.3736045 },
    marker: {},
  }),
  computed: {
    ...mapState(["color"]),
    clientInfo: {
      get: function () {
        return this.$store.state.clientInfo;
      },
    },
  },
  watch: {
    clientInfo: function (client) {
      this.name = client.RazonSocial;
      this.address = client.Direccion;
      this.ClaveCliente = client.Clave;
      this.marker.setPosition({
        lat: client.Latitud,
        lng: client.Longitud,
      });
      this.map.setCenter({
        lat: client.Latitud,
        lng: client.Longitud,
      });
    },
  },
  mounted() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 19,
      center: this.uluru,
    });
    this.marker = new google.maps.Marker({
      position: this.uluru,
      map: this.map,
      draggable: true,
    });
    console.log(this.$route.params.id);
    this.$store.dispatch("GetClient", this.$route.params.id);
  },
  methods: {
    EditaCliente() {
      const pos = this.marker.getPosition();
      let obj = {
        IdCliente: this.ClaveCliente,
        Lat: pos.lat(),
        Lng: pos.lng(),
      };
      this.$store.dispatch("EditClient", obj);
    },
  },
};
</script>
<style></style>
