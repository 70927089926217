import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import actions from "../Modules/actions";
import mutations from "../Modules/mutations";
import state from "../Modules/state";
import getters from "../Modules/getters";

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
});
