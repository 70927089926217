<template>
  <v-app style="background: #fafafa">
    <Navbar v-if="authorized"></Navbar>
    <v-main>
      <Login v-if="!authorized"></Login>
      <v-sheet v-else id="scrolling-techniques-7" class="overflow-y-auto">
        <router-view></router-view>
      </v-sheet>
    </v-main>
    <v-snackbar
      v-model="showSnackbar"
      outlined
      :color="secondColor"
      :timeout="time"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="secondColor"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style>
#scrolling-techniques-7 {
  height: calc(100vh - 96px);
}
</style>

<script>
import { mapState } from "vuex";
import Login from "@/components/login";
import Navbar from "@/components/navbar";
export default {
  name: "App",
  components: {
    Login,
    Navbar,
    Login,
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState(["color", "secondColor", "message", "authorized", "time"]),
    showSnackbar: {
      get: function () {
        return this.$store.state.showSnackbar;
      },
      set: function (val) {
        this.$store.state.showSnackbar = val;
      },
    },
  },
  async mounted() {
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
    const userInfoCache = await localStorage.getItem("UserInfo");
    const TokenCache = await localStorage.getItem("Token");
    const Tabselect = localStorage.getItem("tabSelected");
    if (userInfoCache != null && TokenCache != null) {
      await this.$store.commit("SetLocalStorage", {
        userInfo: JSON.parse(userInfoCache),
        Token: TokenCache,
        Tab: Tabselect != null ? Tabselect : 0,
      });
    }
    if (!this.authorized && this.$route.path != "/login") {
      this.$router.push("/login");
    }
  },
  //Update() {
  //  //window.addEventListener("resize", this.handleResize);
  //  //this.handleResize();
  //  if (!this.authorized && this.$route.path != "/login") {
  //    this.$router.push("/login");
  //  } else {
  //    const userInfo = localStorage.getItem("UserInfo");
  //    console.log(userInfo);
  //    const Token = localStorage.getItem("Token");
  //    console.log(Token);
  //    if (userInfo != null && Token != null) {
  //      this.$store.commit("SetLocalStorage", {
  //        userInfo: JSON.parse(userInfo),
  //        Token: Token,
  //      });
  //    }
  //  }
  //},
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // handleResize() {
    //   this.$store.commit("onResize");
    // },
  },
};
</script>
