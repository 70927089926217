<template>
  <v-container align-center>
    <v-row style="padding-bottom: 25px">
      <v-col cols="12" xl="8" lg="6" md="6">
        <v-text-field
          dense
          outlined
          v-model="claveVendedor"
          label="Clave Vendedor"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="6" md="6">
        <v-btn dark :color="color" block v-on:click="BuscaClaveVendedor()">
          Buscar
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        outlined
        v-model="email"
        :rules="emailRules"
        label="Usuario"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="password"
        :rules="passwordRules"
        label="Password"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="name"
        :rules="nameRules"
        label="Nombre"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="address"
        :rules="addressRules"
        label="Dirección"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="phone"
        :rules="phoneRules"
        label="Telefono"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="phone_secondary"
        label="Telefono secundario"
      ></v-text-field>
      <v-btn
        v-if="userId === -1"
        :dark="valid"
        :disabled="!valid"
        :color="color"
        block
        v-on:click="crearUsuario()"
      >
        Crear Usuario
      </v-btn>
      <v-btn
        v-else
        :dark="valid"
        :disabled="!valid"
        :color="color"
        block
        v-on:click="editarUsuario()"
      >
        Editar Usuario
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CreateUser",
  data: () => ({
    email: "",
    emailRules: [(v) => !!v || "Usuario es requerido"],
    password: "",
    passwordRules: [(v) => !!v || "Password es requerido"],
    name: "",
    nameRules: [(v) => !!v || "Nombre es requerido"],
    address: "",
    addressRules: [(v) => !!v || "Direccion es requerido"],
    phone: "",
    phoneRules: [(v) => !!v || "Telefono es requerido"],
    phone_secondary: "",
    secPhoneRules: [(v) => !!v || "Segundo Telefono es requerido"],
    valid: true,
    userId: "",
    claveVendedor: "",
  }),

  methods: {
    crearUsuario() {
      if (this.$refs.form.validate()) {
        let userInfo = this.infoIntoObject();
        this.$store.dispatch("CreateUser", userInfo);
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Favor de llenar la informacion que le pide.",
        });
      }
    },

    editarUsuario() {
      let userInfo = this.infoIntoObject();
      let id = this.userId;

      this.$store.dispatch("EditUser", { id, userInfo });
    },

    infoIntoObject() {
      let userInfo = {
        email: this.email,
        password: this.password,
        name: this.name,
        address: this.address,
        phone: this.phone,
        phone_secondary: this.phone_secondary,
        claveVendedor: this.claveVendedor,
      };

      return userInfo;
    },
    BuscaClaveVendedor() {
      if (this.claveVendedor != "") {
        this.$store.dispatch("GetInfoVendedor", this.claveVendedor);
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Primero llene la clave vendedor.",
        });
      }
    },
  },

  computed: {
    ...mapState(["color"]),
    gottenUserInfo: {
      get: function () {
        return this.$store.state.gottenUserInfo;
      },
    },
    infoVendedor: {
      get: function () {
        return this.$store.state.infoVendedor;
      },
    },
  },
  watch: {
    gottenUserInfo: function (userInfo) {
      this.email = userInfo.email;
      this.password = userInfo.password;
      this.name = userInfo.name;
      this.address = userInfo.address;
      this.phone = userInfo.phone;
      this.phone_secondary = userInfo.phone_secondary;
      this.claveVendedor = userInfo.ClaveVendedor;
    },
    infoVendedor: function (infoVend) {
      if (infoVend.length > 0) {
        this.name = infoVend[0].Nombre;
        this.address = infoVend[0].Sucursal;
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "No se encontro la clave vendedor o se encuentra desactivada la clave.",
        });
      }
    },
  },

  async mounted() {
    this.userId = this.$route.params.id;
    console.log(this.userId);
    if (this.userId) {
      this.$store.dispatch("getUserInfo", { id: this.userId });
    } else {
      this.userId = -1;
    }
  },
};
</script>
