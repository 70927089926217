<template>
  <v-container align-center>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        dense
        outlined
        v-model="email"
        :rules="emailRules"
        label="Usuario"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="password"
        :rules="passwordRules"
        label="Password"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="name"
        :rules="nameRules"
        label="Nombre"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="address"
        :rules="addressRules"
        label="Dirección"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="phone"
        :rules="phoneRules"
        label="Teléfono"
        required
      ></v-text-field>
      <v-text-field
        dense
        outlined
        v-model="phone_secondary"
        label="Teléfono secundario"
        required
      ></v-text-field>
      <v-row>
        <v-col cols="10">
          <v-select
            v-model="selectedAuthorizedUser"
            :items="listUsers"
            label="Usuarios Autorizados"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }} - {{ data.item.email }}
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.name }} - {{ data.item.email }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-btn block dark :color="color" @click="addSelectedAuthorizedUser()"
            >Añadir</v-btn
          >
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="selectedAuthorizedUsers"
        :items-per-page="50"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">{{
          getNameFromListUsers(item)
        }}</template>
        <template v-slot:item.email="{ item }">{{
          getEmailFromListUsers(item)
        }}</template>
        <template v-slot:item.actions="{ index }">
          <v-btn small color="error" @click="removeAuthorizedUser(index)">
            Remover
            <v-icon small> mdi-account-off </v-icon>
          </v-btn>
        </template></v-data-table
      >
      <v-btn
        v-if="userId === -1"
        dark
        :color="color"
        block
        v-on:click="crearSupervisor()"
      >
        Crear Supervisor
      </v-btn>
      <v-btn v-else dark :color="color" block v-on:click="editarSupervisor()">
        Editar Supervisor
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "createSupervisor",
  data: () => ({
    email: "",
    emailRules: [(v) => !!v || "Email es requerido"],
    password: "",
    passwordRules: [(v) => !!v || "Password es requerido"],
    name: "",
    nameRules: [(v) => !!v || "Nombre es requerido"],
    address: "",
    addressRules: [(v) => !!v || "Dirección es requerido"],
    phone: "",
    phoneRules: [(v) => !!v || "Teléfono es requerido"],
    phone_secondary: "",
    authorizedUsersList: [],
    headers: [
      { text: "Nombre", value: "name" },
      { text: "Usuario", value: "email" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    selectedAuthorizedUsers: [],
    selectedAuthorizedUser: {},
    selectedAuthorizedUsersPostInfo: "",
    userId: "",
  }),

  computed: {
    ...mapState(["color", "listUsers"]),
    gottenUserInfo: {
      get: function () {
        return this.$store.state.gottenUserInfo;
      },
    },
  },

  watch: {
    selectedAuthorizedUsers: function (users) {
      this.selectedAuthorizedUsersPostInfo = users.join(",");
    },
    gottenUserInfo: function (userInfo) {
      this.email = userInfo.email;
      this.password = userInfo.password;
      this.name = userInfo.name;
      this.address = userInfo.address;
      this.phone = userInfo.phone;
      this.phone_secondary = userInfo.phone_secondary;
      this.selectedAuthorizedUsersPostInfo = userInfo.authorized_users;
    },
  },

  methods: {
    addSelectedAuthorizedUser() {
      if (this.selectedAuthorizedUser.IdUsuario) {
        this.selectedAuthorizedUsers.push(
          this.selectedAuthorizedUser.IdUsuario
        );
      }
    },
    getNameFromListUsers(id) {
      for (const user of this.listUsers) {
        if (user.IdUsuario === id) {
          return user.name;
        }
      }
    },
    getEmailFromListUsers(id) {
      for (const user of this.listUsers) {
        if (user.IdUsuario === id) {
          return user.email;
        }
      }
    },
    removeAuthorizedUser(index) {
      this.selectedAuthorizedUsers.splice(index, 1);
    },
    crearSupervisor() {
      let obj = {
        email: this.email,
        password: this.password,
        name: this.name,
        phone: this.phone,
        phone_secondary: this.phone_secondary,
        address: this.address,
        authorized_users: this.selectedAuthorizedUsersPostInfo,
      };
      this.$store.dispatch("createSupervisor", obj);
    },
    editarSupervisor() {
      let obj = {
        IdUsuario: this.userId,
        email: this.email,
        password: this.password,
        name: this.name,
        phone: this.phone,
        phone_secondary: this.phone_secondary,
        address: this.address,
        authorized_users: this.selectedAuthorizedUsersPostInfo,
      };

      this.$store.dispatch("editSupervisor", obj);
    },
  },

  async mounted() {
    await this.$store.dispatch("GetUsers", "user");
    this.userId = this.$route.params.id;
    console.log(this.userId);
    if (this.userId) {
      await this.$store.dispatch("getUserInfo", { id: this.userId });
      let authorizedUserIds = this.gottenUserInfo.authorized_users.split(",");
      let userHash = {};
      for (const userId of authorizedUserIds) {
        this.selectedAuthorizedUsers.push(parseInt(userId));
      }
    } else {
      this.userId = -1;
    }
  },
};
</script>