import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/login";
import Map from "@/components/map";
import ListUsers from "@/components/listUsers";
import CreateUser from "@/components/createUser";
import Tracking from "@/components/tracking";
import createClient from "@/components/createClient";
import listClients from "@/components/listClients";
import createAdmin from "@/components/createAdmin";
import listAdmin from "@/components/listAdmin";
import AssignRoute from "@/components/assignRoute";
import createSupervisor from "@/components/createSupervisor";
import listSupervisor from "@/components/listSupervisor";

Vue.use(VueRouter);

const routes = [
  {
    path: "/map",
    name: "Map",
    component: Map,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/listUsers",
    name: "ListUsers",
    component: ListUsers,
  },
  {
    path: "/createUser/:id?",
    name: "CreateUser",
    component: CreateUser,
  },
  {
    path: "/tracking/:id",
    name: "Tracking",
    component: Tracking,
  },
  {
    path: "/assignRoute/:id",
    name: "AssignRoute",
    component: AssignRoute,
  },
  {
    path: "/createClient/:id?",
    name: "createClient",
    component: createClient,
  },
  {
    path: "/listClients",
    name: "listClients",
    component: listClients,
  },
  {
    path: "/createAdmin/:id?",
    name: "createAdmin",
    component: createAdmin,
  },
  {
    path: "/listAdmin",
    name: "listAdmin",
    component: listAdmin,
  },
  {
    path: "/createSupervisor/:id?",
    name: "createSupervisor",
    component: createSupervisor,
  },
  {
    path: "/listSupervisor",
    name: "listSupervisor",
    component: listSupervisor,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
