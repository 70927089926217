import router from "@/router";

export default {
  snackbar: (state, payload) => {
    console.log(payload);
    state.showSnackbar = payload.snackbar;
    state.message = payload.text;
  },
  progressLogin: (state) => {
    state.progressLogin = !state.progressLogin;
  },
  progressChangePass: (state) => {
    state.progressChangePass = !state.progressChangePass;
  },
  ShowChangePassword: (state, payload) => {
    state.visibleCard = payload;
  },
  onResize: (state) => {
    state.windowSize = { width: window.innerWidth, height: window.innerHeight };
    if (state.windowSize.width <= 460) {
      state.visible = false;
      console.log("Visible = " + state.visible);
    } else {
      state.visible = true;
      console.log("Visible = " + state.visible);
    }
  },
  OpenDrawer: (state) => {
    state.drawer = !state.drawer;
  },
  EnterLogin: (state, payload) => {
    state.progressLogin = !state.progressLogin;
    state.authorized = true;
    state.userInfo = payload.resultado[0];
    state.Token = payload.token;
    switch (state.tab) {
      case 0 || null:
        router.push({ name: "Map" });
        break;
      case 1:
        router.push({ name: "CreateUser" });
        break;
      case 2:
        router.push({ name: "ListUsers" });
        break;
      case 3:
        router.push({ name: "listClients" });
        break;
      case 4:
        router.push({ name: "createAdmin" });
        break;
      case 5:
        router.push({ name: "listAdmin" });
        break;
      case 6:
        router.push({ name: "createSupervisor" });
        break;
      case 7:
        router.push({ name: "listSupervisor" });
        break;
    }
  },
  CambiarTitulo: (state, payload) => {
    state.titulo = payload;
  },
  ExitApp: (state) => {
    state.authorized = false;
    state.userInfo = {};
    state.tab = null;
    localStorage.removeItem("Token");
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("tabSelected");
    router.push({ name: "Login" });
  },
  SetLocalStorage: (state, payload) => {
    state.userInfo = payload.userInfo;
    state.Token = payload.Token;
    state.authorized = true;
    state.tab = parseInt(payload.Tab);
    //router.push({ name: "Map" });
  },
  setUsersMap: (state, payload) => {
    state.UserMap = payload;
  },
  SetListUsers: (state, payload) => {
    state.listUsers = payload;
  },
  SetGottenUserInfo: (state, payload) => {
    state.gottenUserInfo = payload;
  },
  SetUserSOSList: (state, payload) => {
    state.userSOSList = payload;
  },
  SetUserCheckins: (state, payload) => {
    state.userCheckins = payload;
  },
  SetUserFindByUser: (state, payload) => {
    state.userFindByUser = payload;
  },
  setTab: (state, payload) => {
    state.tab = payload;
    localStorage.setItem("tabSelected", payload);
  },
  SetClientInfo: (state, payload) => {
    state.clientInfo = payload;
  },
  SetSucursales: (state, payload) => {
    console.log(payload);
    state.sucursales = payload;
  },
  loadingLClient: (state) => {
    state.loadingLClient = !state.loadingLClient;
  },
  SetListClients: (state, payload) => {
    state.listClients = payload;
  },
  SetListAdmins: (state, payload) => {
    state.listAdmins = payload;
  },
  SetRutaUser: (state, payload) => {
    state.rutaUser = payload;
  },
  SetListSupervisor: (state, payload) => {
    state.listSupervisor = payload;
  },
  SetdialogDes: (state) => {
    state.dialogDes = !state.dialogDes;
  },
  setTextDiagAdmin: (state, payload) => {
    state.textDiagAdmin = payload;
  },
  setTextDiagSupervisor: (state, payload) => {
    state.textDiagSupervisor = payload;
  },
  visibleAction: (state, payload) => {
    state.visibleAction = payload;
  },
  SetdialogAct: (state) => {
    state.dialogAct = !state.dialogAct;
  },
  SetRutaUser: (state, payload) => {
    state.rutaUser = payload;
  },
  setAdminInfo: (state, payload) => {
    state.adminInfo = payload;
  },
  setSwitch: (state, payload) => {
    console.log(payload);
    state.switch1 = payload;
  },
  setvActCAdmin: (state, payload) => {
    state.vActCAdmin = payload;
  },
  settextDiagCAdmin: (state, payload) => {
    state.textDiagCAdmin = payload;
  },
  setInfoVendedor: (state, payload) => {
    state.infoVendedor = payload;
  },
};
