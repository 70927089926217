import router from "@/router";

export default {
  responseError: async ({ state, commit }, payload) => {
    if (payload.status == 401) {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
      commit("ExitApp");
    } else if (payload.status == 403) {
      commit("snackbar", {
        snackbar: true,
        text: payload.mensaje,
      });
      commit("ExitApp");
    }
  },

  login: async ({ state, commit }, payload) => {
    try {
      commit("progressLogin");
      const response = await fetch(state.URL + "/auth/local", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: payload.user,
          password: payload.password,
        }),
      });
      console.log(response);
      const info = await response.json();
      console.log(info);
      if (info.resultado[0].Resultado == 1) {
        if (info.resultado[0].role == "user") {
          commit("progressLogin");
          commit("snackbar", {
            snackbar: true,
            text: "Este usuario no tiene permiso para usar el panel",
          });
        } else {
          localStorage.setItem("UserInfo", JSON.stringify(info.resultado[0]));
          localStorage.setItem("Token", info.token);
          commit("EnterLogin", info);
        }
      } else {
        commit("progressLogin");
        commit("snackbar", {
          snackbar: true,
          text: info.resultado[0].Mensaje,
        });
      }
    } catch (error) {
      commit("progressLogin");
      console.log(error);
    }
  },
  ChangePassword: async ({ state, commit, dispatch }, payload) => {
    if (payload.newPassword == payload.confirmPass) {
      commit("progressChangePass");
      // state.dataUser.password = payload.newPassword;
      // setTimeout(function () {
      //   commit("progressChangePass"),
      //     commit("ShowChangePassword", false),
      //     commit("snackbar", {
      //       snackbar: true,
      //       text: "Contraseña actualizada correctamente.",
      //     });
      // }, 5000);
      try {
        const response = await fetch(state.URL + "/auth/changePassword", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: payload.user,
            oldPassword: payload.lastPassword,
            newPassword: payload.newPassword,
          }),
        });
        const obj = await response.json();
        console.log(obj);
        if (response.status == 200) {
          if (obj[0].Resultado == 0) {
            commit("progressChangePass");
            commit("snackbar", {
              snackbar: true,
              text: obj[0].Descripcion,
            });
          } else {
            commit("progressChangePass");
            commit("ShowChangePassword", false);
            commit("snackbar", {
              snackbar: true,
              text: "La contraseña se cambio correctamente",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje: obj.mensaje,
          };
          dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      commit("snackbar", {
        snackbar: true,
        text: "Las contraseñas ingresadas no coinciden.",
      });
    }
  },

  AllUsers: async ({ state, commit, dispatch }) => {
    try {
      const response = await fetch(
        state.URL +
          "/users/allUsers/" +
          state.userInfo.role +
          "/" +
          state.userInfo.IdUsuario,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.Token,
          },
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        commit("setUsersMap", obj.info);
      } else {
        let body = {
          status: response.status,
          mensaje: obj.mensaje,
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  GetUsers: async ({ state, commit, dispatch }, payload) => {
    try {
      const response = await fetch(state.URL + "/users/getUsers/" + payload, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": state.Token,
        },
      });
      console.log(response);
      const obj = await response.json();
      console.log(obj);
      commit("SetListUsers", obj.info);
      if (response.status == 200) {
        if (payload == "user") {
          commit("SetListUsers", obj.info);
        } else if (payload == "admin") {
          commit("SetListAdmins", obj.info);
        } else if (payload == "supervisor") {
          commit("SetListSupervisor", obj.info);
        }
      } else {
        let body = {
          status: response.status,
          mensaje: obj.mensaje,
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },
  getUserInfo: async ({ state, commit, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.URL + "/users/getUserInfo/" + payload.id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.Token,
          },
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        commit("SetGottenUserInfo", obj.resultado[0]);
      } else {
        let body = {
          status: response.status,
          mensaje: obj.mensaje,
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },

  findUserSOSList: async ({ state, commit, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.URL + "/users/gps-history/findByUserSOS",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.Token,
          },
          body: JSON.stringify({
            IdUsuario: payload.id,
          }),
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        commit("SetUserSOSList", obj.info);
      } else {
        let body = {
          status: response.status,
          mensaje: obj.mensaje,
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },

  findUserCheckins: async ({ state, commit, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.URL + `/users/visitLogs/${payload.id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.Token,
          },
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        commit("SetUserCheckins", obj.info);
      } else {
        let body = {
          status: response.status,
          mensaje: obj.mensaje,
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },

  findByUser: async ({ state, commit, dispatch }, payload) => {
    try {
      const response = await fetch(
        state.URL + `/users/gps-history/findByUser`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": state.Token,
          },
          body: JSON.stringify({
            IdUsuario: payload.id,
            date1: payload.dates[0],
            date2: payload.dates[1],
          }),
        }
      );
      const obj = await response.json();
      if (response.status == 200) {
        commit("SetUserFindByUser", obj.info);
      } else {
        let body = {
          status: response.status,
          mensaje: obj.mensaje,
        };
        dispatch("responseError", body);
      }
    } catch (error) {
      console.log(error);
    }
  },

  GetClient: async ({ state, commit }, payload) => {
    const response = await fetch(state.URL + "/clients/id/" + payload, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });
    //console.log(response);
    const value = await response.json();
    if (response.status == 200) {
      commit("SetClientInfo", value.info[0]);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  EditClient: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.URL + "/clients/edit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: JSON.stringify({
        IdCliente: payload.IdCliente,
        Latitud: payload.Lat,
        Longitud: payload.Lng,
      }),
    });
    const value = await response.json();
    if (response.status == 200) {
      router.push({ name: "listClients" });
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  GetSucursales: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.URL + "/users/sucursales", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });
    const value = await response.json();
    if (response.status == 200) {
      var data = [];
      data.push({ sucursal: "TODOS" });
      value.info.forEach((element) => {
        data.push(element);
      });
      commit("SetSucursales", data);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  GetListClients: async ({ state, commit, dispatch }, payload) => {
    commit("loadingLClient");
    const response = await fetch(state.URL + "/clients/sucursal/" + payload, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });
    const value = await response.json();
    if (response.status == 200) {
      commit("loadingLClient");
      commit("SetListClients", value.info);
    } else {
      commit("loadingLClient");
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  DesactivarUser: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.URL + "/users/delete/" + payload, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });

    const value = await response.json();

    if (response.status == 200) {
      const obj = {
        Title: "",
        Body: "Usuario desactivado",
      };
      commit("visibleAction", 2);
      commit("setTextDiagAdmin", obj);
      dispatch("GetUsers", "admin");
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  ActivarUser: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.URL + "/users/active/" + payload, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });

    const value = await response.json();

    if (response.status == 200) {
      const obj = {
        Title: "",
        Body: "Usuario Activado",
      };
      commit("visibleAction", 2);
      commit("setTextDiagAdmin", obj);
      dispatch("GetUsers", "admin");
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  CreateUser: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    console.log(JSON.stringify(payload));
    const response = await fetch(state.URL + "/users/createUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: JSON.stringify(payload),
    });

    const value = await response.json();

    if (response.status == 200) {
      router.push({ name: "ListUsers" });
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  DeleteUser: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(state.URL + "/users/delete/" + payload, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });

    const value = await response.json();

    if (response.status == 200) {
      state.visibleAction = 3;
      state.textDiagUser = {
        Title: "Usuario desactivado",
        Body: "",
      };
      dispatch("GetUsers", "user");
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  ActivateUser: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(state.URL + "/users/active/" + payload, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });

    const value = await response.json();

    if (response.status == 200) {
      state.visibleAction = 3;
      state.textDiagUser = {
        Title: "Usuario activado",
        Body: "",
      };
      dispatch("GetUsers", "user");
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  EditUser: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(state.URL + "/users/edit/" + payload.id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: JSON.stringify(payload.userInfo),
    });

    const value = await response.json();
    console.log(value);

    if (response.status == 200) {
      router.push({ name: "ListUsers" });
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  getAdminInfo: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.URL + "/users/getUserInfo/" + payload, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: JSON.stringify(payload.userInfo),
    });
    const value = await response.json();
    console.log(value);
    if (response.status == 200) {
      commit("setAdminInfo", value.resultado[0]);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  createAdmin: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(state.URL + "/users/CreateAdmin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: JSON.stringify(payload),
    });

    const value = await response.json();

    if (response.status == 200) {
      // router.push({ name: "listAdmin" });
      commit("setvActCAdmin", 2);
      const text = {
        Title: "",
        Body: "Guardado correctamente.",
      };
      commit("settextDiagCAdmin", text);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  editAdmin: async ({ state, commit, dispatch }, payload) => {
    const response = await fetch(
      state.URL + "/users/EditAdmin/" + payload.IdUsuario,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": state.Token,
        },
        body: JSON.stringify(payload),
      }
    );

    const value = await response.json();

    if (response.status == 200) {
      commit("setvActCAdmin", 2);
      const text = {
        Title: "",
        Body: "Editado correctamente.",
      };
      commit("settextDiagCAdmin", text);
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  DesactivarSupervisor: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(state.URL + "/users/delete/" + payload, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });
    const value = await response.json();
    if ((response.status == 200, payload)) {
      const obj = {
        Title: "Usuario Desactivado",
        Body: "Finalizado",
      };
      state.visibleAction = 2;
      state.textDiagSupervisor = obj;
      dispatch("GetUsers", "supervisor");
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },
  ActivarSupervisor: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(state.URL + "/users/active/" + payload, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });
    const value = await response.json();
    if ((response.status == 200, payload)) {
      const obj = {
        Title: "Usuario Activado",
        Body: "Finalizado",
      };
      state.visibleAction = 2;
      state.textDiagSupervisor = obj;
      dispatch("GetUsers", "supervisor");
    } else {
      let body = {
        status: response.status,
        mensaje: value.mensaje,
      };
      dispatch("responseError", body);
    }
  },

  GetRutaUser: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(state.URL + "/users/ruta/" + payload.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
    });

    const value = await response.json();
    console.log(value);

    if (response.status == 200) {
      commit("SetRutaUser", value.info);
    } else {
      dispatch("responseError", value);
    }
  },

  EditUserRuta: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    let body = JSON.stringify(payload.rutas);
    console.log(body);
    const response = await fetch(state.URL + "/users/EditaRuta/" + payload.id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: body,
    });

    const value = await response.json();
    console.log(value);

    if (response.status == 200) {
      router.push({ name: "ListUsers" });
    } else {
      dispatch("responseError", value);
    }
  },

  createSupervisor: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    let body = JSON.stringify(payload);
    console.log(body);
    const response = await fetch(state.URL + "/users/UserSup/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": state.Token,
      },
      body: body,
    });

    const value = await response.json();
    console.log(value);

    if (response.status == 200) {
      router.push({ name: "listSupervisor" });
    } else {
      dispatch("responseError", value);
    }
  },

  editSupervisor: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    let body = JSON.stringify(payload);
    console.log(body);
    const response = await fetch(
      state.URL + "/users/UserSupEdit/" + payload.IdUsuario,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": state.Token,
        },
        body: body,
      }
    );

    const value = await response.json();
    console.log(value);

    if (response.status == 200) {
      router.push({ name: "listSupervisor" });
    } else {
      dispatch("responseError", value);
    }
  },

  GetInfoVendedor: async ({ state, commit, dispatch }, payload) => {
    console.log(payload);
    const response = await fetch(
      state.URL + "/users/TraeInfoVendedorPanel/" + payload,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": state.Token,
        },
      }
    );

    const value = await response.json();
    if (response.status == 200) {
      commit("setInfoVendedor", value);
    } else {
      dispatch("responseError", value);
    }
  },
};
