<template>
  <v-container align-center>
    <v-data-table
      :headers="headers"
      :items="listUsers"
      :items-per-page="50"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar usuarios"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.index="{ item, index }">
        {{ listUsers.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="primary"
          @click="GoRoute(`tracking/${item.IdUsuario}`)"
        >
          Ver
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
        <v-btn
          small
          color="success"
          @click="GoRoute(`createUser/${item.IdUsuario}`)"
        >
          Editar
          <v-icon small> mdi-circle-edit-outline </v-icon>
        </v-btn>
        <v-btn
          small
          color="success"
          @click="GoRoute(`assignRoute/${item.IdUsuario}`)"
        >
          Asignar Ruta
          <v-icon small> mdi-circle-edit-outline </v-icon>
        </v-btn>
        <v-btn
          v-if="item.active"
          small
          color="error"
          @click="DeleteUserDialogue(item.IdUsuario)"
        >
          Desactivar
          <v-icon small> mdi-account-off </v-icon>
        </v-btn>
        <v-btn
          v-else
          small
          color="success"
          @click="ActivateUserDialog(item.IdUsuario)"
        >
          Activar
          <v-icon small> mdi-account </v-icon>
        </v-btn>
      </template></v-data-table
    ><v-dialog v-model="dialogDes" max-width="400" style="border-radius: 12px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <sweetalert-icon v-if="this.visibleAction == 0" icon="warning" />
              <sweetalert-icon
                v-else-if="this.visibleAction == 1"
                icon="loading"
              />
              <sweetalert-icon v-else icon="success" />
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h2>{{ textDiagUser.Title }}</h2>
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h3>{{ textDiagUser.Body }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 12px 16px">
          <v-spacer></v-spacer>
          <v-btn
            v-if="visibleAction == 3"
            color="success"
            @click="dialogDes = false"
          >
            Aceptar
          </v-btn>
          <v-btn
            v-if="visibleAction == 0"
            color="grey lighten-4"
            @click="dialogDes = false"
          >
            No
          </v-btn>
          <v-btn
            v-if="visibleAction == 0"
            color="red"
            dark
            v-on:click="DeleteUser()"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAct" max-width="400" style="border-radius: 12px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <sweetalert-icon v-if="this.visibleAction == 0" icon="warning" />
              <sweetalert-icon
                v-else-if="this.visibleAction == 1"
                icon="loading"
              />
              <sweetalert-icon v-else icon="success" />
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h2>{{ textDiagUser.Title }}</h2>
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h3>{{ textDiagUser.Body }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 12px 16px">
          <v-spacer></v-spacer>
          <v-btn
            v-if="visibleAction == 3"
            color="success"
            @click="dialogAct = false"
          >
            Aceptar
          </v-btn>
          <v-btn
            v-if="visibleAction == 0"
            color="grey lighten-4"
            @click="dialogAct = false"
          >
            No
          </v-btn>
          <v-btn
            v-if="visibleAction == 0"
            color="red"
            dark
            v-on:click="ActivateUser()"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ListUsers",
  data() {
    return {
      headers: [
        { text: "#", value: "index" },
        { text: "Usuario", value: "email" },
        { text: "Nombre", value: "name" },
        { text: "Teléfono", value: "phone" },
        { text: "Teléfono secundario", value: "phone_secondary" },
        { text: "Dirección", value: "address" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      search: "",
      dialogDes: false,
      idToToggle: "",
      dialogAct: false,
    };
  },

  computed: {
    ...mapState(["color", "textDiagUser", "visibleAction"]),
    listUsers: {
      get: function () {
        return this.$store.state.listUsers;
      },
    },
    visibleAction: {
      get: function () {
        return this.$store.state.visibleAction;
      },
      set: function (val) {
        return (this.$store.state.visibleAction = val);
      },
    },
    textDiagUser: {
      get: function () {
        return this.$store.state.textDiagUser;
      },
      set: function (val) {
        return (this.$store.state.textDiagUser = val);
      },
    },
  },

  methods: {
    GoRoute(ruta) {
      this.$router.push(ruta);
    },
    DeleteUserDialogue(id) {
      this.textDiagUser = {
        Title: "Desactivar Usuario",
        Body: "¿Desea desactivar este usuario?",
      };
      this.idToToggle = id;
      this.visibleAction = 0;
      this.dialogDes = !this.dialogDes;
    },
    DeleteUser() {
      this.$store.dispatch("DeleteUser", this.idToToggle);
      this.visibleAction = 1;
    },
    ActivateUserDialog(id) {
      this.textDiagUser = {
        Title: "Activar Usuario",
        Body: "¿Desea activar este usuario?",
      };
      this.idToToggle = id;
      this.visibleAction = 0;
      this.dialogAct = !this.dialogAct;
    },
    ActivateUser(id) {
      this.$store.dispatch("ActivateUser", this.idToToggle);
    },
  },

  async mounted() {
    this.$store.dispatch("GetUsers", "user");
  },
};
</script>
