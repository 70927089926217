<template>
  <div>
    <v-container align-center>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Usuario"
            rules="required"
          >
            <v-text-field
              dense
              outlined
              v-model="user"
              :error-messages="errors"
              label="Usuario"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Password"
            rules="required"
          >
            <v-text-field
              dense
              outlined
              v-model="password"
              :error-messages="errors"
              label="Password"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Nombre"
            rules="required"
          >
            <v-text-field
              dense
              outlined
              v-model="name"
              :error-messages="errors"
              label="Nombre"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Telefono"
            rules="required|length:10"
          >
            <v-text-field
              dense
              outlined
              v-model="number"
              :error-messages="errors"
              type="number"
              label="Telefono"
              :counter="10"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Telefono Secundario"
            rules="length:10"
          >
            <v-text-field
              dense
              outlined
              v-model="SNumber"
              :error-messages="errors"
              type="number"
              label="Telefono Secundario"
              :counter="10"
            ></v-text-field>
          </validation-provider>
          <v-btn
            v-if="visible"
            :disabled="invalid"
            :dark="!invalid"
            :color="color"
            block
            type="submit"
            v-on:click="CreaAdmin()"
          >
            Crea Admin
          </v-btn>
          <v-btn
            v-else
            :disabled="invalid"
            :dark="!invalid"
            :color="color"
            block
            v-on:click="EditaAdmin()"
          >
            Edita Admin
          </v-btn>
        </v-form>
      </validation-observer>
      <v-dialog
        v-model="dialogSave"
        max-width="400"
        style="border-radius: 12px"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <sweetalert-icon v-if="vActCAdmin == 1" icon="loading" />
                <sweetalert-icon v-else icon="success" />
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h2>{{ textDiagCAdmin.Title }}</h2>
              </v-col>
            </v-row>
            <v-row style="text-align: center">
              <v-col cols="12" style="padding: 5px">
                <h3>{{ textDiagCAdmin.Body }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 12px 16px">
            <v-spacer></v-spacer>
            <v-btn
              v-if="vActCAdmin == 2"
              color="success"
              dark
              v-on:click="Guardar()"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  required,
  digits,
  email,
  max,
  regex,
  min,
  length,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} no puede ir vacio.",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("min", {
  ...min,
  message: "{_field_} may not be greater than {length} characters",
});

extend("length", {
  ...length,
  message: "{_field_} tiene que tener 10 digitos.",
});

export default {
  name: "createAdmin",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    user: "",
    password: "",
    name: "",
    number: null,
    SNumber: null,
    visible: true,
    dialogSave: false,
  }),
  computed: {
    ...mapState(["color"]),
    adminInfo: {
      get: function () {
        return this.$store.state.adminInfo;
      },
    },
    textDiagCAdmin: {
      get: function () {
        return this.$store.state.textDiagCAdmin;
      },
      set: function (val) {
        return (this.$store.state.textDiagCAdmin = val);
      },
    },
    vActCAdmin: {
      get: function () {
        return this.$store.state.vActCAdmin;
      },
      set: function (val) {
        return (this.$store.state.vActCAdmin = val);
      },
    },
  },
  watch: {
    adminInfo: function (admin) {
      console.log(admin);
      this.user = admin.email;
      this.password = admin.password;
      this.name = admin.name;
      this.number = admin.phone;
      this.SNumber = admin.phone_secondary;
    },
  },
  mounted() {
    console.log(this.$route.params);
    if (this.$route.params.id == undefined || this.$route.params.id == null) {
      this.visible = true;
    } else {
      this.visible = false;
      this.$store.dispatch("getAdminInfo", this.$route.params.id);
    }
  },
  methods: {
    CreaAdmin() {
      const obj = {
        email: this.user,
        password: this.password,
        name: this.name,
        phone: this.number,
        phone_secondary: this.SNumber,
        address: "",
      };
      this.dialogSave = true;
      this.vActCAdmin = 1;
      this.textDiagCAdmin = {
        Title: "Guardando",
        Body: "Por favor, espere...",
      };
      this.$store.dispatch("createAdmin", obj);
    },
    EditaAdmin() {
      const obj = {
        IdUsuario: this.$route.params.id,
        email: this.user,
        password: this.password,
        name: this.name,
        phone: this.number,
        phone_secondary: this.SNumber,
        address: "",
      };
      this.dialogSave = true;
      this.vActCAdmin = 1;
      this.textDiagCAdmin = {
        Title: "Editando",
        Body: "Por favor, espere...",
      };
      this.$store.dispatch("editAdmin", obj);
    },
    Guardar() {
      this.$router.push({ name: "listAdmin" });
    },
  },
};
</script>
<style></style>
