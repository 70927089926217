<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col xl="6" lg="6" md="8" cols="12" align="center" justify="center">
          <v-img
            max-height="160"
            max-width="160"
            src="../assets/logo-grupo-jomar.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col xl="6" lg="6" md="8" cols="12">
          <v-card>
            <v-card-title style="justify-content: center; background: #003479">
              <h2 v-if="visible" style="color: white">
                Jomar Industrias S.A. de C.V.
              </h2>
              <h4 v-else style="color: white">Jomar Industrias S.A. de C.V.</h4>
            </v-card-title>
            <v-card-subtitle
              align="center"
              justify="center"
              style="background: #003479; padding-top: 16px"
            >
              <h3 style="color: white">Panel Administrativo CAT</h3>
            </v-card-subtitle>
            <v-divider v-if="!progressLogin"></v-divider>
            <v-progress-linear
              indeterminate
              :color="secondColor"
              v-else
            ></v-progress-linear>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="user"
                  :rules="userRules"
                  label="Cuenta"
                  required
                  clearable
                  :color="color"
                  outlined
                ></v-text-field>
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="show ? 'text' : 'password'"
                  label="Password"
                  v-model="password"
                  class="input-group--focused"
                  @click:append="show = !show"
                  @keydown.enter.prevent="Ingresar()"
                  :color="color"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-checkbox
                v-model="visibleCard"
                label="Cambiar password"
                dense
                :color="color"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-btn v-on:click="Ingresar()" :color="secondColor" dark>
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-progress-linear
            indeterminate
            :color="secondColor"
            v-if="progressChangePass"
          ></v-progress-linear>
          <v-card v-if="visibleCard">
            <v-card-text>
              <v-form>
                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  label="Password anterior"
                  v-model="passwordAnt"
                  class="input-group--focused"
                  @click:append="show2 = !show2"
                  :color="color"
                  clearable
                  outlined
                ></v-text-field>
                <v-text-field
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  label="Password"
                  v-model="passwordNew"
                  class="input-group--focused"
                  @click:append="show3 = !show3"
                  :color="color"
                  clearable
                  outlined
                ></v-text-field>
                <v-text-field
                  :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show4 ? 'text' : 'password'"
                  label="Password"
                  v-model="confirmPass"
                  class="input-group--focused"
                  @click:append="show4 = !show4"
                  :color="color"
                  clearable
                  outlined
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-on:click="CambiarPassword()" :color="secondColor" dark>
                <v-icon left dark> mdi-content-save </v-icon> Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Login",
  data: () => ({
    valid: true,
    user: null,
    userRules: [(v) => !!v || "Cuenta es requerido"],
    show: false,
    password: null,
    passwordRules: [(v) => !!v || "Password es requerida"],
    passwordAnt: null,
    show2: false,
    passwordNew: null,
    show3: false,
    confirmPass: null,
    show4: false,
  }),
  computed: {
    ...mapState([
      "color",
      "progressLogin",
      "secondColor",
      "progressChangePass",
      "visible",
    ]),
    visibleCard: {
      get: function () {
        return this.$store.state.visibleCard;
      },
      set: function (val) {
        this.$store.commit("ShowChangePassword", val);
      },
    },
    switch1: {
      get: function () {
        return this.$store.state.switch1;
      },
      set: function (val) {
        this.$store.commit("setSwitch", val);
      },
    },
  },
  watch: {
    visibleCard: function (val) {
      if (!val) {
        if (this.passwordAnt != null || this.passwordAnt != undefined) {
          this.passwordAnt = null;
          this.passwordNew = null;
          this.confirmPass = null;
        }
      }
    },
  },
  methods: {
    Ingresar() {
      var validForm = this.$refs.form.validate();
      if (validForm) {
        var obj = {
          user: this.user,
          password: this.password,
        };
        this.$store.dispatch("login", obj);
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Favor de ingresar los campos requeridos.",
        });
      }
    },
    CambiarPassword() {
      var obj = {
        user: this.user,
        lastPassword: this.passwordAnt,
        newPassword: this.passwordNew,
        confirmPass: this.confirmPass,
      };
      this.$store.dispatch("ChangePassword", obj);
    },
  },
};
</script>

<style></style>
