export default {
  userInfo: {},
  Token: "",
  URL: "https://jomarwebws.jomarweb.com",
  //URL: "http://192.168.6.192:3000",
  color: "#003479",
  progressLogin: false,
  secondColor: "#e70033",
  progressChangePass: false,
  visible: false,
  authorized: false,
  showSnackbar: false,
  message: "",
  time: 2000,
  UserMap: [],
  visibleCard: false,
  menuA: [
    { name: "Mapa", path: "/map" },
    { name: "Crear Usuario", path: "/createUser" },
    { name: "Ver Usuarios", path: "/listUsers" },
    { name: "Ver Clientes", path: "/listClients" },
    { name: "Crear Admin", path: "/createAdmin" },
    { name: "Ver Admin", path: "/listAdmin" },
    { name: "Crear Supervisor", path: "/createSupervisor" },
    { name: "Ver Supervisor", path: "/listSupervisor" },
  ],
  menuS: [
    { name: "Mapa", path: "/map" },
    { name: "Crear Usuario", path: "/createUser" },
    { name: "Ver Usuarios", path: "/listUsers" },
    { name: "Ver Clientes", path: "/listClients" },
  ],
  listUsers: [],
  gottenUserInfo: {},
  userSOSList: [],
  userCheckins: [],
  userFindByUser: [],
  tab: null,
  /*Pantalla createClient*/
  clientInfo: {},
  sucursales: {},
  /*Pantalla de listClients*/
  loadingLClient: false,
  HeadersLClients: [
    {
      text: "#",
      value: "index",
    },
    { text: "Clave", value: "Clave" },
    { text: "Nombre", value: "RazonSocial" },
    { text: "Dirección", value: "Direccion" },
    { text: "Acciones", value: "actions", sortable: false },
  ],
  listClients: [],
  /*Pantalla de ListAdmin*/
  HeadersLAdmins: [
    {
      text: "#",
      value: "index",
    },
    { text: "Usuario", value: "email" },
    { text: "Nombre", value: "name" },
    { text: "Telefono", value: "phone" },
    { text: "Telefono Secundario", value: "phone_secondary" },
    { text: "Acciones", value: "actions", sortable: false },
  ],
  listAdmins: [],
  loadingLAdmin: false,
  rutaUser: [],
  dialogDes: false,
  visibleAction: 0,
  textDiagAdmin: {
    Title: "Desactivar Admin",
    Body: "¿Desea desactivar este admin?",
  },
  visibleAction: 0,
  textDiagAdmin: {
    Title: "Desactivar Usuario",
    Body: "¿Desea desactivar este usuario?",
  },
  dialogAct: false,
  textDiagUser: {
    Title: "Desactivar Usuario",
    Body: "¿Desea desactivar este usuario?",
  },
  /*Pantalla de ListSupervisor*/
  HeadersLSupervisor: [
    {
      text: "#",
      value: "index",
    },
    { text: "Usuario", value: "email" },
    { text: "Nombre", value: "name" },
    { text: "Telefono", value: "phone" },
    { text: "Telefono Secundario", value: "phone_secondary" },
    { text: "", value: "actions", sortable: false },
  ],
  listSupervisor: [],
  contrDiagSupervisor: false,
  textDiagSupervisor: {
    Title: "Desactivar Usuario",
    Body: "¿Desea desactivar este usuario?",
  },
  /*Pantalla de createAdmin */
  adminInfo: {},
  vActCAdmin: 1,
  textDiagCAdmin: {
    Title: "",
    Body: "",
  },
  infoVendedor: [],
};
